//firebase.js

import { initializeApp } from "firebase/app"
// import { analytics } from "firebase/analytics";
// import * as firebaseConfig from "./env-config";

// // Initialize the Firebase app in the service worker by passing in
// // your app's Firebase config object.
// // https://firebase.google.com/docs/web/setup#config-object
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// importScripts('https://www.gstatic.com/firebasejs/7.21.1/firebase-app.js');
// importScripts('https://www.gstatic.com/firebasejs/7.21.1/firebase-messaging.js');

const firebaseConfig = {
	apiKey: process.env.VUE_APP_FIREBASE_API_KEY || "api-key-not-set",
	authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || "env-not-set",
	databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL || "env-not-set",
	projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID || "env-not-set",
	storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET || "env-not-set",
	messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID || "env-not-set",
	appId: process.env.VUE_APP_FIREBASE_APP_ID || "env-not-set",
	measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID || "env-not-set",
}

// var vapidKey = function () { return process.env.VUE_APP_vapidKey; };
// // // Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)

// firebase.messaging().getToken({ vapidKey: vapidKey })
export default firebaseApp
