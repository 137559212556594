const dbVersion = "26"
import moment from "moment"
import fetchApiRequest from "../../store/fetchApiRequest"

const objStoreNames = [
	// <<< Client Metadata Object Store Names
	{ entityName: "orderDeliveryTypes", keyPath: "orderDeliveryTypeId" },
	{ entityName: "orderStatus", keyPath: "orderStatusId" },
	{ entityName: "orderItemStatus", keyPath: "orderItemStatusId" },
	{ entityName: "warehousesList", keyPath: "warehouseId" },
	{ entityName: "warehouseOrderStatus", keyPath: "warehouseOrderStatusId" },
	{ entityName: "modified_on", keyPath: "id" }, // common result set on all deltas
	// >>>
	{ entityName: "stockTypes", keyPath: "stockTypeId" },
	{ entityName: "tripLegStockTypes", keyPath: "tripLegStockTypeId" },
	{ entityName: "tripLegs", keyPath: "tripLegId" },
	{ entityName: "tripTypes", keyPath: "tripTypeId" },
	{ entityName: "trips", keyPath: "tripId" },
	{ entityName: "slots", keyPath: "slotId" },
	{ entityName: "outboundWarehouses", keyPath: "outboundId" }, // scheduler outbounds
	{ entityName: "vehicleContracts", keyPath: "contractId" }, //// scheduler vehicle contracts
	{ entityName: "crateTypes", keyPath: "crateTypeId" },
	{ entityName: "tripStates", keyPath: "tripStatusId" },
	{ entityName: "contractTypes", keyPath: "contractTypeId" },
	{ entityName: "vendors", keyPath: "vendorId" }, //  setting => vendors delta
	{ entityName: "warehouseContracts", keyPath: "contractId" },
	{ entityName: "users", keyPath: "userId" }, //  setting => users delta
	{ entityName: "warehouses", keyPath: "warehouseId" }, //  setting => warehouses delta
	{ entityName: "vehicles", keyPath: "vehicleId" }, //  setting => vehicles delta
	{ entityName: "drivers", keyPath: "driverId" }, //  setting => driver delta
	{ entityName: "tripTrackingStatus", keyPath: "tripLegId" }, //  Arrival and Depture => trip vehicle Tracking
	{ entityName: "vehicleTrackers", keyPath: "gpsUnitId" },
	{ entityName: "routes", keyPath: "routeId" },
	{ entityName: "orderItems", keyPath: "orderItemDetailId" },
]

export default {
	// Open and Connect to indexeddb database if exists or creates if not exists
	async getDb(dbName) {
		//Checking for IndexedDB Support
		if ("indexedDB" in window) {
			return new Promise((resolve, reject) => {
				let request = window.indexedDB.open(dbName, dbVersion)
				request.onerror = (e) => {
					// console.log('Error opening db', e);
					reject("Error")
				}
				request.onsuccess = (e) => {
					let db = e.target.result
					resolve(db)
				}
				// Creates the object stores with id as keys
				request.onupgradeneeded = (e) => {
					let dbResult = e.target.result // entityname,keypathid, indexing object

					objStoreNames.forEach((item) => {
						if (!dbResult.objectStoreNames.contains(item)) {
							let osTriplegStockTypes = dbResult.createObjectStore(item.entityName, {
								keyPath: item.keyPath,
							})
							if (item.entityName === "tripLegStockTypes") {
								// creates a index with the tripLegId on the tripLegStockTypes object store
								osTriplegStockTypes.createIndex("tripLegId_index", "tripLegId")
							}
						}
					})
				}
			})
		}
	},
	async indexedDBFetchApi(apiMethod, requestParams, userId) {
		try {
			// defining the indexeddb name
			let dbName = window.location.hostname + "/" + apiMethod + "/" + userId

			if (requestParams.tenantId) dbName += "/" + requestParams.tenantId

			if (requestParams.warehouseId) dbName += "/" + requestParams.warehouseId

			if (requestParams.date) dbName = dbName + "/" + requestParams.date

			if (requestParams.orderId) dbName = dbName + "/" + requestParams.orderId

			await this.storeIndexedDBDatabaseName(dbName, dbVersion)

			let db = await this.getDb(dbName)
			if (db) {
				let lastsyn = []

				lastsyn = await this.setLastModifiedDate(db)

				requestParams.lastSync = JSON.stringify(lastsyn || {})

				const response = await fetchApiRequest(apiMethod, requestParams)

				if (response && response.response && response.status === "success") {
					//   null response handle

					let trans = db.transaction(
						Object.keys(response.response).filter((store) => objStoreNames.map((o) => o.entityName).includes(store)),
						"readwrite"
					)

					// delete the tripLegStockTypes from store when the response object has the tripLegStockTypes
					if (trans.objectStoreNames.contains("tripLegStockTypes")) {
						let tripLegIdsDeltaForStockList = response.response.tripLegStockTypes.map(function (arr) {
							return arr.tripLegId
						})

						tripLegIdsDeltaForStockList = Array.from(new Set(tripLegIdsDeltaForStockList))
						tripLegIdsDeltaForStockList = tripLegIdsDeltaForStockList.sort((a, b) => {
							return a - b
						})
						await this.deleteIfTripLegStockTypeExists(trans, tripLegIdsDeltaForStockList)
					}
					await this.updateTripList(response, trans)

					let getTripListResponse = await this.getTripList(response, trans)

					trans.oncomplete = function () {
						db.close()
					}
					trans.onerror = function () {
						console.log(trans.error)
					}
					return getTripListResponse
				} // if no response from db server ???
			} else {
				const response = await fetchApiRequest(apiMethod, requestParams)
				return response
			}
		} catch (error) {
			// API or DB or Parsing or server
			console.log(error.message)
			if (window.indexedDB && typeof window.indexedDB.databases === "undefined") {
				await this.deleteIndexedDBDatabaseByName()
			} else {
				await this.deleteIndexedDBDataBase()
			}
			const response = await fetchApiRequest(apiMethod, requestParams)
			return response
			// if (error.message == "Failed to fetch") {
			//     return { err: "Failed to fetch", response: null, status: "error" };
			// }
			// return { err: JSON.stringify(error, Object.getOwnPropertyNames(error)), response: null, status: "error" };
		}
	},
	// delete the tripleg stock types for the modified triplegs from the object store
	async deleteIfTripLegStockTypeExists(trans, tripLegIdsDeltaForStockList) {
		if (tripLegIdsDeltaForStockList.length > 0) {
			var tripLegIdsDeltaForStockListVar = tripLegIdsDeltaForStockList.map(async (id) => {
				return new Promise((resolve, reject) => {
					var objectStore = trans.objectStore("tripLegStockTypes").index("tripLegId_index")
					objectStore.openCursor(id).onsuccess = function (event) {
						var cursor = event.target.result
						if (cursor) {
							trans.objectStore("tripLegStockTypes").delete(Number(cursor.primaryKey))
							cursor.continue()
						} else {
							resolve()
						}
					}
				}).catch((err) => {
					//console.log(err);
				})
			})
			await Promise.all(tripLegIdsDeltaForStockListVar)
		}
	},
	// update the existing object stores
	async updateTripList(response, trans) {
		return Promise.all(
			Object.keys(response.response)
				.filter((store) => objStoreNames.map((o) => o.entityName).includes(store))
				.map(async (objStoreName) => {
					if (response.response[objStoreName] && response.response[objStoreName].length > 0) {
						await Promise.all(
							response.response[objStoreName].map(async (objStoreItem) => {
								return new Promise((resolve, reject) => {
									var req = trans.objectStore(objStoreName).put(objStoreItem)
									req.onsuccess = function () {
										resolve()
									}
									req.onerror = function (e) {
										console.log(e)
										reject(e)
									}
								})
							})
						).catch((err) => {
							//console.log(err);
						})
					}
				})
		)
	},
	// fetch data from the object stores in idb
	async getTripList(response, trans) {
		let responseObj = response

		await Promise.all(
			Object.keys(response.response)
				.filter((store) => objStoreNames.map((o) => o.entityName).includes(store))
				.map(async (objStoreName) => {
					let objStore = trans.objectStore(objStoreName).getAll()
					return new Promise((resolve, reject) => {
						objStore.onsuccess = function () {
							if (responseObj?.response?.isCompleteData?.[0]?.isCompleteData) {
								// If the DB response is full response (isCompleteData), clear current object store data (Fix to delete local entries that are deleted in server)
								trans.objectStore(objStoreName).clear()
							} else {
								// Else merge DB response with IDB response
								responseObj.response[objStoreName] = objStore.result
							}
							resolve()
						}
					})
				})
		).catch((err) => {
			//console.log(err);
		})
		// outboundwares contains the contextWarehouse object
		if (response.response.contextWarehouse) {
			responseObj.response.contextWarehouse = response.response.contextWarehouse
		}
		if (response.response.tripLegCrates) {
			responseObj.response.tripLegCrates = response.response.tripLegCrates
		}
		if (response.response.totalRecords) {
			responseObj.response.totalRecords = response.response.totalRecords
		}
		// Filtering Cleared Trips & Trip Legs
		if (Array.isArray(response.response.clearedTrips) && response.response.clearedTrips.length > 0) {
			let clearedTripIds = response.response.clearedTrips.map((trip) => trip.bulkClearTripId)
			let activeTrips = [],
				activeTripLegs = []
			let deletedTrips = [],
				deletedTripLegs = []

			responseObj.response.trips.forEach((trip) => {
				if (!clearedTripIds.includes(trip.tripId)) {
					activeTrips.push(trip)
				} else {
					deletedTrips.push(trans.objectStore("trips").delete(trip.tripId))
				}
			})
			responseObj.response.trips = activeTrips
			Promise.all(deletedTrips).then(() => {
				console.log(deletedTrips.length, "trips successfully deleted from store.")
			})

			responseObj.response.tripLegs.forEach((leg) => {
				if (!clearedTripIds.includes(leg.tripId)) {
					activeTripLegs.push(leg)
				} else {
					deletedTripLegs.push(trans.objectStore("tripLegs").delete(leg.tripLegId))
				}
			})
			responseObj.response.tripLegs = activeTripLegs
			Promise.all(deletedTripLegs).then(() => {
				console.log(deletedTripLegs.length, "tripLegs successfully deleted from store.")
			})
		}

		responseObj.status = response.status
		return responseObj
	},
	// Delete all the indexedDB databases
	async deleteIndexedDBDataBase() {
		const dbs = await window.indexedDB.databases()
		dbs.forEach((db) => {
			window.indexedDB.deleteDatabase(db.name)
		})
	},

	async handleTripUpdate(apiMethod, reqParams, tripDetails) {
		const { warehouseId, date, tenantId, userId } = reqParams

		const {
			tripId,
			activeContractId,
			cancelDescription,
			clientTripReferenceId,
			contractTypeId,
			currentTripLegId,
			driverFirstName,
			driverId,
			driverLastName,
			driverPhoneNumber,
			fitnessExpiryDate,
			hasEPODImages,
			id,
			insuranceExpiryDate,
			loadCapacityInTonnes,
			permitExpiryDate,
			routeCode,
			routeId,
			scheduledForDate,
			scheduledInWarehouseName,
			shift,
			taxExpiryDate,
			tripCreatedBy,
			tripCreatedOn,
			tripModifiedBy,
			tripModifiedOn,
			tripType,
			tripTypeId,
			vehicleBodyTypeId,
			vehicleBodyTypeName,
			vehicleDisplayNumber,
			vehicleId,
			vehicleMakeName,
			vehicleMaxCrates,
			vehicleMaxPallets,
			vehicleModelName,
			vehicleNumber,
			vehicleSizeInFt,
		} = tripDetails

		const dbName = `${window.location.hostname}/${apiMethod}/${userId}/${tenantId}/${warehouseId}/${date}`
		let db = await this.getDb(dbName)
		let tripTransaction = db.transaction(["trips"], "readwrite")
		let tripLegTransaction = db.transaction(["tripLegs"], "readwrite")
		let tripsObjectStore = tripTransaction.objectStore("trips")
		let tripLegsObjectStore = tripLegTransaction.objectStore("tripLegs")
		const updateTrip = tripsObjectStore.put({
			tripId,
			activeContractId,
			cancelReason: cancelDescription,
			clientTripReferenceId,
			contractTypeId,
			currentTripLegId,
			driverFirstName,
			driverId,
			driverLastName,
			driverPhoneNumber,
			fitnessExpiryDate,
			hasEPODImages,
			id,
			insuranceExpiryDate,
			loadCapacityInTonnes,
			permitExpiryDate,
			routeCode,
			routeId,
			scheduledForDate,
			scheduledInWarehouseName,
			shift,
			taxExpiryDate,
			tripCreatedBy,
			tripCreatedOn,
			tripModifiedBy,
			tripModifiedOn,
			tripType,
			tripTypeId,
			vehicleBodyTypeId,
			vehicleBodyTypeName,
			vehicleDisplayNumber,
			vehicleId,
			vehicleMakeName,
			vehicleMaxCrates,
			vehicleMaxPallets,
			vehicleModelName,
			vehicleNumber,
			vehicleSizeInFt,
		})
		updateTrip.onsuccess = () => {
			console.log("trip updated")
		}
		updateTrip.onerror = () => {
			console.error("trip not updated")
		}

		const tripLegs = tripDetails.tripLegs

		for (let i = 0; i < tripLegs.length; i++) {
			const {
				actualArrivalTime,
				actualDispatchTime,
				contractId,
				contractVehicleDriverId,
				contractVehicleId,
				distanceTravelledInKms,
				driverFirstName,
				driverId,
				driverLastName,
				driverPhoneNumber,
				eta,
				etaUpdatedOn,
				fitnessExpiryDate,
				fromWarehouseId,
				fromWarehouseLatitude,
				fromWarehouseLongitude,
				fromWarehouseName,
				fromWarehouseShortName,
				id,
				insuranceExpiryDate,
				inverseOutboundId,
				isDroppingAvailable,
				isPickupAvailable,
				isReverseTripleg,
				legGatepassStatusId,
				legRank,
				legStatusId,
				legStockStatusId,
				loadCapacityInTonnes,
				outboundId,
				permitExpiryDate,
				scheduledArrivalTime,
				scheduledStartTime,
				toWarehouseId,
				toWarehouseLatitude,
				toWarehouseLongitude,
				toWarehouseName,
				toWarehouseShortName,
				tripId,
				tripLegId,
				vehicleBodyTypeId,
				vehicleBodyTypeName,
				vehicleDisplayNumber,
				vehicleId,
				vehicleMakeName,
				vehicleMaxCrates,
				vehicleMaxPallets,
				vehicleModelName,
				vehicleNumber,
				vehicleSizeInFt,
			} = tripLegs[i]
			const updateTripLegs = tripLegsObjectStore.put({
				actualArrivalTime,
				actualDispatchTime,
				contractId,
				contractVehicleDriverId,
				contractVehicleId,
				distanceTravelledInKms,
				driverFirstName,
				driverId,
				driverLastName,
				driverPhoneNumber,
				eta,
				etaUpdatedOn,
				fitnessExpiryDate,
				fromWarehouseId,
				fromWarehouseLatitude,
				fromWarehouseLongitude,
				fromWarehouseName,
				fromWarehouseShortName,
				id,
				insuranceExpiryDate,
				inverseOutboundId,
				isDroppingAvailable,
				isPickupAvailable,
				isReverseTripleg,
				legGatepassStatusId,
				legRank,
				legStatusId,
				legStockStatusId,
				loadCapacityInTonnes,
				outboundId,
				permitExpiryDate,
				scheduledArrivalTime,
				scheduledStartTime,
				toWarehouseId,
				toWarehouseLatitude,
				toWarehouseLongitude,
				toWarehouseName,
				toWarehouseShortName,
				tripId,
				tripLegId,
				vehicleBodyTypeId,
				vehicleBodyTypeName,
				vehicleDisplayNumber,
				vehicleId,
				vehicleMakeName,
				vehicleMaxCrates,
				vehicleMaxPallets,
				vehicleModelName,
				vehicleNumber,
				vehicleSizeInFt,
			})

			updateTripLegs.onsuccess = () => {
				console.log("tripleg updated")
			}
			updateTripLegs.onerror = () => {
				console.error("tripleg not updated")
			}
		}

		if (updateTrip) return true
	},

	async setLastModifiedDate(db) {
		let lastsyn = []
		return new Promise((resolve, reject) => {
			let objModifiedDateStore = db.transaction("modified_on", "readonly")
			objModifiedDateStore.oncomplete = () => {
				resolve()
			}
			let objStore = objModifiedDateStore.objectStore("modified_on").getAll()
			objStore.onsuccess = function () {
				if (objStore.result.length > 0) {
					objStore.result.forEach((item) => {
						let modified_on = item.modified_on != null ? moment(item.modified_on, "YYYY-MM-DD HH:mm:ss").add({ minutes: 0 }).format("YYYY-MM-DD HH:mm:ss") : null
						lastsyn.push({
							entity: item.entity,
							modified_on,
						})
					})
				}
				resolve(lastsyn)
			}
		}).catch((err) => {
			//console.log(err);
		})
	},
	// store the newly created indexed db name to local storage
	async storeIndexedDBDatabaseName(name, version) {
		if (window.indexedDB && typeof window.indexedDB.databases === "undefined") {
			const LOCALSTORAGE_CACHE_KEY = "indexedDBDatabases"

			// Store a key value map of databases
			const getFromStorage = () => JSON.parse(window.localStorage[LOCALSTORAGE_CACHE_KEY] || "{}")

			// Write the database to local storage
			const writeToStorage = (value) => (window.localStorage[LOCALSTORAGE_CACHE_KEY] = JSON.stringify(value))

			const localStorage = () => {
				const dbName = name
				const version = version
				const existing = getFromStorage()
				writeToStorage({ ...existing, [name]: dbName })
			}

			localStorage()
		}
	},
	// delete the indexed db based on local storage
	async deleteIndexedDBDatabaseByName() {
		if (window.indexedDB && typeof window.indexedDB.databases === "undefined") {
			const LOCALSTORAGE_CACHE_KEY = "indexedDBDatabases"

			// Store a key value map of databases
			const getFromStorage = () => JSON.parse(window.localStorage[LOCALSTORAGE_CACHE_KEY] || "{}")
			// Write the database to local storage

			const localStorage = () => {
				const existing = getFromStorage()
				const db = Object.values(existing)
				db.forEach((element) => {
					window.indexedDB.deleteDatabase(element)
				})
			}
			return localStorage()
		}
	},
}
