const notes = [
	{
		version: "2.5.0",
		releaseDate: "Mon, Jun 27 2022",
		newFeatures: [
			"<p><b>Web:</b>Create Trip Popup - Added feature to update driver for a contract in a trip from the create trip Popup.</p>",
			"<p><b>Web:</b>Trip Scheduler Filters - Added Vehicle Filters in create trip popup to search and filter vehicles in multiple criteria.</p>",
			"<p><b>Web:</b>Added Vehicle Name with Expiry Indicator in all areas except reports.</p>",
			"<p><b>Web:</b>Added Date Range Picker for Driver Action/Gate Pass Reports.</p>",
			"<p><b>Web:</b>Trip Scheduler - Added Location search.</p>",
			"<p><b>Web:</b>Upload Documents functionality implemented for contracts, vehicle, vendor and driver update forms</p>",
		],
		improvements: [
			"<p><b>Web:</b>Removed National Permit Expiry in save vehicle form.</p>",
			"<p><b>Web:</b>Multi select dropdown picker implemented in required areas of the application.  </p>",
			"<p><b>Web:</b>Trip Tracking - Added Trip Type Icon for trips & Filter by Trip Type.</p>",
			"<p><b>Web:</b>Detail Asset Tracking Report - Updated default view to dispatch.  </p>",
		],
		knownIssues: [],
		bugFixes: [],
	},
	{
		version: "2.4.0",
		releaseDate: "Thu, Mar 28 2022",
		newFeatures: [
			"<p><b>Mobile:</b>Added option to select stock types for each pallet</p>",
			"<p><b>Mobile:</b>Added option to select user preferred language</p>",
			"<p><b>Web:</b>Settings - Added Option to make master data active and inactive</p>",
		],
		improvements: ["<p><b>Mobile:</b>Updated OTL Flow - OTL is required only for the next location</p>", "<p><b>Web:</b>Settings page UI enhancements</p>"],
		knownIssues: [],
		bugFixes: [],
	},
	{
		version: "2.3.0",
		releaseDate: "Thu, Jan 29 2022",
		newFeatures: ["<p><b>Web:</b>Transport billing report</p>", "<p><b>Web:</b>One user One session in mobile and web application</p>"],
		improvements: ["<p><b>Web:</b>Reports page new design</p>", "<p><b>Web:</b>Archival policy</p>"],
		knownIssues: [],
		bugFixes: [],
	},
	{
		version: "2.2.0",
		releaseDate: "Thu, Dec 09 2021",
		newFeatures: [
			"<p><b>Web:</b> Print Schedule – From the Trip Scheduler page, outbound can print the schedule of the day using Print button and can share it with the transport team for quick view of created trips and vehicle assignment.</p>",
			"<p><b>Web:</b> Advance Scheduling – a) Given option for the user to select multiple dates or date range in the advance scheduling feature.</p>",
			"<p><b>Web:</b> Advance Scheduling – b) Removed restriction on the future dates selection [user can select next month dates and has option to do advance scheduling]</p>",
			"<p><b>Web:</b> Add Reverse Logistics – In the create trip page for multi city trip, provided button to add reverse trip legs based on forward trip legs for quick trip creation and minimize manual mistakes</p>",
			'<p><b>Web:</b> Proceeds – In the gate pass, added "Proceeds" crate type. Now the "T4 cities" can enter the proceeds information in these crate type.</p>',
			'<p><b>Web:</b> Reports – Proceeds information is added in "All Attributes" and “Detailed Assets Tracking” reports',

			"<p><b>Mobile:</b> User can now skip Loading Steps at outbound when there are no assets to be sent.</p>",
			"<p><b>Mobile:</b> User can now skip Unloading steps at inbound when there are no gate passes to be approved. </p>",
			"<p><b>Mobile:</b> Added option to Download and Share Gate pass from Gate pass details. </p>",
			'<p><b>Mobile:</b> Provided option to switch between "Locations" under the "more" global menu. </p>',
		],
		improvements: [
			"<p><b>Web:</b> Reports  – Updated exported excel cells to have colored background based on defined criteria",
			'<p><b>Mobile:</b> Added "From" and "To" attributes to the trip leg card view for outbound and security roles. </p>',
		],
		knownIssues: [],
		bugFixes: [],
	},
	{
		version: "2.1.0",
		releaseDate: "Tue, Nov 16 2021",
		newFeatures: [
			"<p><b>Web:</b> Enabled Advance Scheduling feature – User can copy the trips from current or past date to next 7 days</p>",
			"<p><b>Web:</b> Clear Schedule Feature – user can delete/clear the trips which are copied or created manually before vehicle is assigned</p>",
		],
		improvements: [
			"<p><b>Web:</b> Added the following columns in Vehicle level Dashboard (Export to excel): MTD Days, FTD Days, Contract Deactivated Date, Is Contract Active</p>",
			"<p><b>Web:</b> User can select desired date in all the pages using date picker instead of selecting dates using next and previous arrows</p>",
			"<p><b>Web:</b> Scheduler Page UI enhancements: Added trips count for MDC and 5K Stores/drop points</p>",
			"<p><b>Web:</b> Scheduler Page UI enhancements: Added trip type icon for trip card</p>",
			"<p><b>Mobile:</b> Ready for loading option is provided to the Outbound executive along with driver.</p>",
			"<p><b>Mobile:</b> Handling accidental app exist by showing a confirmation popup</p>",
		],
		knownIssues: [],
		bugFixes: [],
	},
	{
		version: "2.0.0",
		releaseDate: "Fri, Oct 22 2021",
		newFeatures: [
			"<p><b>Web:</b> Implementation of CEE role (to accommodate T4 process).</p>",
			'<p><b>Web:</b> Reports - Implemented "Driver Login" report.</p>',
			'<p><b>Web:</b> Reports - Implemented "Driver Action" report.</p>',
			'<p><b>Web:</b> Reports - Implemented "Vehicle Wise Summary report.</p>',
		],
		improvements: [
			"<p><b>Mobile:</b> When a user (other than Driver) Ends Trip, Driver receives a notification of the same.</p>",
			'<p><b>Web:</b> Implemented calendar-date picker on "Trip Scheduler" and "Trip Tracking" pages.</p>',
			'<p><b>Web:</b> Added "Locations" filter option on "Trip Tracking" Page.</p>',
			'<p><b>Web:</b> All Attributes report - Export to Excel: added "Outbound Gate pass Generated by" column.</p>',
			'<p><b>Web:</b> "Trip Scheduler" Page - User\'s Page view slides to current time of the day (for current day).</p>',
			'<p><b>Web:</b> "Dashboard" Page - calendar-date picker - Implemented previous and next day navigation.</p>',
		],
		knownIssues: [],
		bugFixes: [],
	},
	{
		version: "1.8.2",
		releaseDate: "Mon, Sep 13 2021",
		newFeatures: [],
		improvements: [
			'<p><b>Mobile:</b> When a trip is started by Security instead of Driver, "Trip started" notification is sent to Driver.</p>',
			"<p><b>Web:</b> All Attributes report - Export to Excel: Updated Date column to scheduled date instead of created date.</p>",
		],
		knownIssues: [],
		bugFixes: ["<p><b>Web:</b> Detailed Assets Tracking Report - Fixed incorrect counts and missing crate types.</p>"],
	},
	{
		version: "1.8.1",
		releaseDate: "Mon, August 23 2021",
		newFeatures: [
			"<p><b>Mobile:</b> Multiple gatepasses:  Users can now create or add multiple gatepasses for multiple locations.</p>",
			'<p><b>Web:</b> Multiple gatepasses: Ability to print multiple gatepasses from "Trip Tracking" page.</p>',
			'<p><b>Web:</b> Dashboard: Added a new menu item "Dashboard" with clickable charts. Clicking on Charts will navigate user to related list of items.</p>',
		],
		improvements: [],
		knownIssues: [],
		bugFixes: [],
	},
]
export { notes }
