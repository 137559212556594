import { register } from "register-service-worker"
import Vue from "vue"
import Toasted from "vue-toasted"
import idb from "./app/Helpers/indexedDBHelpers/indexedDBApiRequest"
import firebaseApp from "./firebase"
import { getMessaging, onMessage, getToken } from "firebase/messaging"
import { getAnalytics } from "firebase/analytics"

Vue.use(Toasted)
if (process.env.NODE_ENV === "production") {
	Vue.prototype.$messaging = getMessaging(firebaseApp)
}
Vue.prototype.$analytics = getAnalytics(firebaseApp)

// Vue.prototype.$messaging = firebase.messaging();

function reloadApp() {}

var vapidKey = process.env.VUE_APP_FIREBASE_VAPID_KEY || "env-not-set"

if (process.env.NODE_ENV === "production") {
	const receivedNotificationsChannel = new BroadcastChannel("received-notification")

	register(`${process.env.BASE_URL}firebase-messaging-sw.js`, {
		ready(registration) {
			console.log("App is being served from cache by a service worker")

			// console.log(registration);
			// // Get Instance ID token. Initially this makes a network call, once retrieved
			// // subsequent calls to getToken will return from cache.
			//messaging.usePublicVapidKey("BEplf3102H2-psL8NslArTJo0uEDXowUwsSabxg8IR5_VM7l5MsuNjr9q8k50Up72IlH18awBJDz9HvnVl-lzbM");
			// Vue.prototype.$messaging.getToken({
			//     vapidKey: vapidKey,
			//     serviceWorkerRegistration: registration
			// }).then((currentToken) => {
			//     console.log(currentToken);
			//     // if (currentToken) {
			//     //   sendTokenToServer(currentToken);
			//     //   updateUIForPushEnabled(currentToken);
			//     // } else {
			//     //   // Show permission request.
			//     //   console.log('No Instance ID token available. Request permission to generate one.');
			//     //   // Show permission UI.
			//     //   updateUIForPushPermissionRequired();
			//     //   setTokenSentToServer(false);
			//     // }
		},
		registered(registration) {
			console.log("Service worker has been registered.")

			getToken(Vue.prototype.$messaging, { vapidKey: vapidKey })
				.then((currentToken) => {
					if (currentToken) {
						// Send the token to your server and update the UI if necessary
						// ...
						localStorage.setItem("fcmToken", currentToken)
						console.log(currentToken)
					} else {
						// Show permission request UI
						console.log("No registration token available. Request permission to generate one.")
						// ...
					}
				})
				.catch((err) => {
					console.log("An error occurred while retrieving token. ")
					// ...
				})

			onMessage(Vue.prototype.$messaging, (payload) => {
				// console.log("Message received. ", payload)
				receivedNotificationsChannel.postMessage(payload)
				if (!(payload.data?.title || payload.notification?.title)) {
					return
				}

				// ...
				const notificationTitle = payload.data.title ? payload.data.title : payload.notification.title ? payload.notification.title : "TMS"
				const tag = payload.data?.tag ?? payload.data?.tripLegId ?? "GeneralNotification"
				const notificationOptions = {
					body: payload.data.body ? payload.data.body : payload.notification.body ? payload.notification.body : "TMS Message",
					icon: "/img/icons/android-chrome-192x192.png",
					badge: "/img/icons/android-chrome-192x192.png",
					tag,
					data: payload.data || {},
				}

				// Uncomment the following line to receive notifications
				if (!document.hidden) {
					registration.showNotification(notificationTitle, notificationOptions)
				}

				// {
				//     // actions?: NotificationAction[],
				//     // badge?: string,
				//     body: payload.data.body,
				//     // data?: any,
				//     // dir?: NotificationDirection,
				//     // icon?: string,
				//     // image?: strin,
				//     // lang?: string,
				//     // renotify?: boolean,
				//     // requireInteraction?: boolean,
				//     // silent?: boolean,
				//     // tag?: string,
				//     // timestamp?: number,
				//     // vibrate?: VibratePattern,
				// });
			})
		},
		cached() {
			console.log("Content has been cached for offline use.")
		},
		updatefound(registration) {
			console.log("New content is downloading.")
		},
		updated(registration) {
			Vue.toasted.show("New Version Available !", {
				// you can pass a single action as below
				duration: null,
				position: "bottom-right",
				// you can pass a multiple actions as an array of actions
				action: [
					{
						text: "Reload",
						onClick: (e, toastObject) => {
							registration.waiting.postMessage({ action: "skipWaiting" })

							// delete the existing indexed db on of reload
							if (window.indexedDB && typeof window.indexedDB.databases === "undefined") {
								const deleteId = async () => {
									try {
										await idb
											.deleteIndexedDBDatabaseByName()
											.then(() => {
												localStorage.removeItem("indexedDBDatabases")
											})
											.catch(console.error)
									} catch ({ error }) {
										console.log("error while deleting idb")
									}
								}
								deleteId()
							} else {
								const deleteIdb = async () => {
									try {
										await idb
											.deleteIndexedDBDataBase()
											.then(() => {
												localStorage.removeItem("indexedDBDatabases")
											})
											.catch(console.error)
									} catch ({ error }) {
										console.log("error while deleting idb")
									}
								}
								deleteIdb()
							}

							setTimeout(() => {
								window.location.reload()
							}, 1000)
						},
					},
					{
						text: "Cancel",
						onClick: (e, toastObject) => {
							toastObject.goAway(0)
						},
					},
				],
			})
		},
		offline() {
			console.log("No internet connection found. App is running in offline mode.")
		},
		error(error) {
			console.error("Error during service worker registration:", error)
		},
	})
}
