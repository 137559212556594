import { logEvent } from "firebase/analytics"
import Vue from "vue"
import lodash from "lodash"
import axios from '@/axios'

axios.interceptors.request.use(request => {
    logEvent(Vue.prototype.$analytics, `Request :: ${request.method} - ${request.url}`)
    request.headers.Authorization = `Bearer ${localStorage.getItem("authorizationToken")}`
    return request
})
axios.interceptors.response.use(response => {
    if (response.status === 403) {
        alert("Your session has been expired. Please login again")
        removeAuthTokenAndNavToLogin()
    }
    if (response.data) {
        if (response.data.err && response.data.err.errno === 401) {
            alert("Your current device is logged out as you have logged in from another device. Please login again")
            removeAuthTokenAndNavToLogin()
        }
        if (response.headers["content-type"] === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") return response

        response.data.status = response.data.err ? "error" : "success"
        return response.data
    }
}, error => {
    if (error.code === "ERR_NETWORK") {
        Vue.prototype.$toasted.error("You are offline, please check you connection", {
            position: "bottom-right",
            duration: 5000,
        })
    }
    if (error.request.status === 403) {
        removeAuthTokenAndNavToLogin()
    }
})

async function apiRequest(httpMethod, apiMethod, queryParams, requestParams, contentType = null, responseType = null) {
    if (queryParams) {
        queryParams = lodash.omitBy(queryParams, lodash.isNil)
        const queryString = new URLSearchParams(queryParams)
        apiMethod += `?${queryString}`
    }
    const endPoint = "/api/" + apiMethod
    axios.defaults.headers.common["Content-Type"] = contentType ? contentType : "application/json"
    axios.defaults.responseType = responseType ? responseType : "json"
    return await axios.request({
        url: endPoint,
        method: httpMethod,
        data: requestParams || null,
    })
}

export default async function (apiMethod, requestParams, contentType, responseType) {
    return await apiRequest("POST", apiMethod, undefined, requestParams, contentType, responseType)
}

export async function getApi(endpoint, queryParams, responseType) {
    return await apiRequest("GET", endpoint, queryParams, undefined, undefined, responseType)
}

function removeAuthTokenAndNavToLogin() {
    let url
    localStorage.removeItem('authorizationToken')
    const params = new URLSearchParams(window.location.search)
    const token = params.get('permissions')
    const directNavUrl = window.location.pathname
    if (token && directNavUrl) {
        url = `/login.html?permissions=${token}&directNavUrl=${directNavUrl}`
    } else {
        url = '/login.html'
    }
    window.location.replace(url)
    return
}